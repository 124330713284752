<template>
    <div>
        <div class="nav">
            <div class="title">角色管理</div>
            <div class="add"><el-button type="primary" @click="showAddDialog()">添加角色</el-button></div>
        </div>  
        <el-card class="box-card">
        <div class="text item">
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-input placeholder="请输入角色名称" v-model="queryInfo.roleName" clearable @clear="getRolesList">
                        <el-button slot="append" icon="el-icon-search" @click="searchBtn"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-select v-model="queryInfo.status" placeholder="请选择状态" label="状态" clearable @change="selectStatus(queryInfo.status)">
                        <el-option label="启用" value="1">启用</el-option>
                        <el-option label="禁用" value="0">禁用</el-option>
                    </el-select>
                </el-col>
                
                <!-- <el-col :span="4">
                    <el-button type="primary" v-show="$_has('roles:add')" @click="showAddDialog()">添加角色</el-button>
                </el-col> -->

                <el-col :span="8">
                    <el-button type="success" v-show="$_has('roles:upbatch')" @click="shelfStateChangedUp()">批量上架</el-button>
                    <el-button type="warning" v-show="$_has('roles:downbatch')" @click="shelfStateChangedDown()">批量下架</el-button>
                    <el-button type="danger" v-show="$_has('roles:deletebatch')" @click="removeByIds()">批量删除</el-button>
                </el-col>
            </el-row>

            <el-table :data="roleList" style="width: 100%" border stripe ref="multipleTable" @selection-change="handleSelectionChange">
                <!--展开列-->
                <!-- <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-row v-for="(item1,i1) in scope.row.children" :key="item1.id">
                            
                            <el-col :span="5">
                                <el-tag>{{item1.authName}}</el-tag>
                            </el-col>
                            <el-col :span="19"></el-col>
                        </el-row>
                        <pre>{{scope.row}}</pre>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column type="selection" label="全选" width="55"></el-table-column> -->
                <el-table-column type="index"></el-table-column>
                <el-table-column prop="roleName" label="角色名称" ></el-table-column>
                <el-table-column prop="roleCode" label="角色编码" ></el-table-column>
                <!-- <el-table-column prop="createTime" label="创建时间" ></el-table-column> -->
                <el-table-column label="状态" >
                    <template v-slot="scope">
                        <el-switch 
                                @change="rolesStateChanged(scope.row)"
                                v-model="scope.row.status"
                                :active-value="1"
                                :inactive-value="0"
                                v-if="scope.row.roleCode!='superadmin'"
                                >
                        </el-switch>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="创建人" prop="realname"></el-table-column> -->
                <el-table-column label="操作" width="300px" align="center" header-align="center">
                    <template slot-scope="scope">
                        <!--v-if="scope.row.roleCode!='admin'"-->
                        <!--编辑-->
                        <el-button type="primary" v-show="$_has('roles:edit')" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"  v-if="scope.row.roleCode!='superadmin'">编辑</el-button>
                        <!--删除-->
                        <el-button type="danger" v-show="$_has('roles:delete')" icon="el-icon-delete" size="mini" @click="removeRolesById(scope.row.id)"  v-if="scope.row.roleCode!='superadmin'">删除</el-button>
                        <!--分配权限-->
                        <el-tooltip class="item" effect="dark" content="分配权限" placement="top" :enterable="false">
                            <!--v-if="scope.row.roleCode!='admin'"     -->
                            <el-button type="warning" v-show="$_has('roles:setrights')" icon="el-icon-setting" size="mini" @click="showRightsDialog(scope.row.id)" >分配权限</el-button>
                        </el-tooltip>
                        
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                background
                ayout="prev, pager, next"
                prev-text="上一页"
                next-text="下一页"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.pageNo"
                :page-sizes="[10,20,50]"
                :page-size="queryInfo.pageSize"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
        </el-card>

        <!--添加用户的对话框 ；async是vue1 现在vue2改为v-model-->
        <el-dialog
            :title="this.addOReditDialogVisible == 0 ? '添加角色' : '修改角色'"
            :visible.sync="addDialogVisible"
            width="50%"
            @close="addDialogClosed"
            :close-on-click-modal="false">
            <!--主题内容区-->
            <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px">
                
                <el-form-item label="角色名称" prop="roleName">
                    <el-input v-model="addForm.roleName"></el-input>
                </el-form-item>
                <el-form-item label="角色编码" prop="roleCode">
                    <el-input v-model="addForm.roleCode"></el-input>
                </el-form-item>

                <el-form-item label="角色描述" prop="description">
                    <el-input v-model="addForm.description"></el-input>
                </el-form-item>
                
                <!--该表status为int类型 所以需要加上：-->
                <el-form-item label="是否启用" prop="status">
                    <el-switch 
                            v-model="addForm.status"
                            :active-value="1"
                            :inactive-value="0"
                            >
                    </el-switch>
                </el-form-item>
                
                
                <el-form-item label="id" prop="id" v-show="false" hidden="true">
                    {{addForm.id}}
                </el-form-item>
            </el-form>
            <!--底部区-->
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" v-if="this.addOReditDialogVisible == 0" @click="addRoles">确 定</el-button>
                <el-button type="primary" v-else-if="this.addOReditDialogVisible == 1" @click="editRoles">确 定</el-button>
            </span>
        </el-dialog>

        <!--分配权限对话框-->
        <el-dialog
            title="分配权限"
            :visible.sync="rightsDialogVisible"
            width="50%"
            @close="rightsDialogClosed"
            :close-on-click-modal="false">
            <!--主题内容区-->
            
            <!--node-key="id" 具体选定的值
            default-expand-all="true" 默认全部展开-->
            <el-tree
                    :data="rightsList"
                    :props="treeProps"
                    show-checkbox
                    node-key="id"
                    :default-expand-all="true"
                    :default-checked-keys="this.rightsFormShuzu.lastpermissionIds"
                    ref="treeRef"
                    @check-change="handleCheckChangeTree">
            </el-tree>
            <!--底部区-->
            <span slot="footer" class="dialog-footer">
                <el-button @click="rightsDialogVisible = false">取 消</el-button>
                <el-button type="primary"  @click="saveRights">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            multipleSelection: [],// 多选
            roleList:[],
            queryInfo:{
                roleName:'',
                status:'',

                pageNo:0,//当前页数
                pageSize:10//当前每页显示多少条
            },
            total:0, //共多少条数据

            addOReditDialogVisible:0, //0是add，1是edit

            addDialogVisible:false,//控制对话框的显示与隐藏
            addForm:{
                id:'',
                roleName:'',
                roleCode:'',
                description:'',
                status:1,
                realname:''

                
            },//添加用户的表单数据
            addFormRules:{//验证规则
                roleName:[
                    {required:true,message:'请输入角色名称',trigger:'blur'},
                ],
                roleCode:[
                    {required:true,message:'请输入角色编码',trigger:'blur'},
                ],
            },
            addFormRef:{},


            rightsDialogVisible:false,//控制对话框的显示与隐藏

            
            rightsList:[],//授权 权限树
            treeProps:{
                label:'name',
                children:'children'
            },
            
            rightsFormShuzu:{
                
                //默认选中的菜单权限数组id值
                lastpermissionIds:[],
                //当前即将分配权限的id
                roleId:'',
                //最终选中的菜单权限数组id值
                permissionIds:[]
            },
            //拆分后的表单
            rightsForm:{
                //默认选中的菜单权限数组id值
                lastpermissionIds:'',
                //当前即将分配权限的id
                roleId:'',
                
                //最终选中的菜单权限数组id值
                permissionIds:''
            }
            
            
        }
    },
    created(){
        this.getRolesList();
    },
    methods:{
        toggleSelection(rows) {
            if (rows) {
            rows.forEach(row => {
                this.$refs.multipleTable.toggleRowSelection(row);
                console.log("#########");
            });
            } else {
            this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            
            this.multipleSelection = val;
            //console.log(val);
            
        },

        //获取角色列表
        async getRolesList(){
            const {data:res} = await this.$http.get('sys/role/list',{params:this.queryInfo});
            console.log(res);
            if(res.code !== 200){
                return this.$message.error('获取角色列表失败');
            }
            this.roleList = res.result.records;
            this.total = res.result.total;
        },
        //监听pagesize改变事件
        handleSizeChange(newSize){
            console.log(newSize);
            this.queryInfo.pageSize = newSize;
            this.getRolesList();
        },
        //监听页码值改变的事件
        handleCurrentChange(newPage){
            console.log(newPage);
            this.queryInfo.pageNo = newPage;
            this.getRolesList();
        },
        handleCheckChangeTree(){},
        //点击搜索按钮
        searchBtn(){
            this.queryInfo.pageNo=1
            this.getRolesList()
        },
        //显示添加用户的对话框
        async showAddDialog(){
            // const {data:res} = await this.$http.get('sys/user/queryUserRoleName?userid='+JSON.parse(window.sessionStorage.getItem('userInfo')).id);
            // console.log(res);
            // if(res.code !== 200){
            //     return this.$message.error('获取角色失败');
            // }
            // console.log(res.result);
            // this.addForm.roles = res.result
            this.addDialogVisible = true
        },
        //监听用户添加Dialog关闭事件
        addDialogClosed(){
            this.$refs.addFormRef.resetFields()
            this.addOReditDialogVisible=0
        },
        //添加角色
        addRoles(){
            this.$refs.addFormRef.validate(async valid=>{
                 console.log(valid);
                 if(!valid) return
                 //可以发起网络请求
                 const {data:res} = await this.$http.post('sys/role/add',this.addForm);
                 if(res.code !== 201) {
                     //少一个return？
                     return this.$message.error('添加角色失败');
                 } //缺少判断菜单名是否重复
                
                 this.$message.success('添加角色成功');
                 this.addDialogVisible = false;//关闭添加窗口
                 this.getRolesList();//从新获取列表数据
             })
        },
        //显示编辑用户的对话框
        async showEditDialog(id){
            console.log(id);
            this.addOReditDialogVisible=1;
            const {data:res} = await this.$http.get('sys/role/queryById/?id='+id);
            console.log(res);
            if(res.code !== 200){
                return this.$message.error('查询角色失败');
            }
            //问题的本质是因为你编辑时，第一次打开dialog的时候给表单绑定的form赋值了，这时候这个form的初始值就变成了你所赋值的值，所以resetFields的时候，会将form对应的每个值重置到初始值，这时候的初始值就是你编辑时赋值的那个值, 而不是在data里声明的初始值， 
            //解决方式是，等dialog已经初始化之后再给form赋值，也就是
            this.addDialogVisible = true
            this.$nextTick(() => { 
                this.addForm = res.result
            })
        },
        //修改角色
        editRoles(){
            this.$refs.addFormRef.validate(async valid=>{
                 console.log(valid);
                 if(!valid) return
                 //可以发起网络请求
                 const {data:res} = await this.$http.put('sys/role/edit',this.addForm);
                 if(res.code !== 201) {
                     //少一个return？
                     return this.$message.error('修改角色失败');
                 } //缺少判断菜单名是否重复
                
                 this.$message.success('修改角色成功');
                 this.addDialogVisible = false;//关闭添加窗口
                 this.getRolesList();//重新获取列表数据
             })
        },
        //删除     
        //promis用于异步操作，asyne／await语法糖将异步操作的同步写法 根then用法是一样的 还避免了回调地狱
        //将同步写异步代码，代码会采用非阻塞的方式继续执行下去，解决回调地狱的问题
        async removeRolesById(id){
            //询问是否删除
            const confirmResult = await this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            //如果用户确认删除，返回字符串confirm，如果用户点击取消，则返回值魏字符串cancel
            console.log(confirmResult)
            if(confirmResult !== 'confirm'){
                return this.$message.info('已经取消删除');
            }
            console.log('确认了删除');
            const {data:res} = await this.$http.delete('sys/role/delete?id='+id);
            if(res.code !== 200) {
                //少一个return？
                return this.$message.error('删除角色失败');
            } 
            this.$message.success('删除角色成功');
            console.log("删除角色成功");
            this.getRolesList();//重新获取菜单列表数据
            console.log("刷新列表");
        },

        //监听授权Dialog关闭事件
        rightsDialogClosed(){
            // this.rightsForm.permissionIds = [];
            // this.rightsForm.lastpermissionIds = [];
            // this.rightsForm.roleId = '';
        },

        //显示分配权限的对话框
        async showRightsDialog(id){
            this.rightsForm.roleId = '';
            this.rightsForm.roleId = id;

            const {data:res} = await this.$http.get('sys/permission/list');
            console.log("第一步 获取所有菜单");
            console.log(res);
            if(res.code !== 200){
                return this.$message.error('查询权限失败');
            }

            //得到角色拥有的菜单权限id
            const {data:res2} = await this.$http.get('sys/permission/queryRolePermission?roleId='+id);
            console.log("第二部 根据角色id获取所有菜单");
            console.log("res2");
            console.log(res2);
            if(res2.code !== 200){
                return this.$message.error('查询角色拥有的权限失败');
            }
            //问题的本质是因为你编辑时，第一次打开dialog的时候给表单绑定的form赋值了，这时候这个form的初始值就变成了你所赋值的值，所以resetFields的时候，会将form对应的每个值重置到初始值，这时候的初始值就是你编辑时赋值的那个值, 而不是在data里声明的初始值， 
            //解决方式是，等dialog已经初始化之后再给form赋值，也就是
            this.rightsDialogVisible = true


            
            this.rightsList = []
            this.rightsList = res.result
            
            //备份结束
            res2.result.forEach(val=>{
                this.$nextTick(()=>{
                    this.$refs.treeRef.setChecked(val,true,false)
                })
            })

            //this.rightsFormShuzu.lastpermissionIds =[]
            
            //备份开始
            //this.rightsFormShuzu.lastpermissionIds = res2.result
            this.rightsFormShuzu.temppermissionIds = res2.result//临时存储
            console.log("给rightsFormShuzu.lastpermissionIds付值");
            console.log(this.rightsFormShuzu.lastpermissionIds);
            
            // console.log("显示分配权限对话框");
            // console.log(this.rightsFormShuzu.permissionIds);
            
        },

        //授权
        async saveRights(){

            console.log('第三步 点击保存按钮 获取选中的值');
            const keys = [
                ...this.$refs.treeRef.getCheckedKeys(),
                ...this.$refs.treeRef.getHalfCheckedKeys(),
            ];
            this.rightsForm.permissionIds = '';
            this.rightsForm.permissionIds = keys.join(',');
            this.rightsForm.lastpermissionIds = [];
            this.rightsForm.lastpermissionIds = this.rightsFormShuzu.temppermissionIds.join(',');

            console.log("第四部 拆分字符串");
            console.log(this.rightsForm.lastpermissionIds);
            console.log(this.rightsForm.permissionIds);

            
            //可以发起网络请求
            console.log("发起网路请求 最终传 from");
            console.log(this.rightsForm);
            const {data:res} = await this.$http.post('sys/permission/saveRolePermission',this.rightsForm);
            if(res.code !== 201) {
                //少一个return？
                return this.$message.error('分配权限失败');
            } //缺少判断菜单名是否重复
        
            this.$message.success('分配权限成功');
            this.rightsDialogVisible = false;//关闭添加窗口
            
        },

        //搜索 状态下拉
        selectStatus(status){
            console.log(status+"~~~~~~~~~~~");
            this.queryInfo.status = status;
            this.searchBtn();
        },

        //监听switch开关的状态 1正常 2冻结
        async rolesStateChanged(roleinfo){
            console.log(roleinfo);
            const {data:res} = await this.$http.put('sys/role/frozenBatch',{ids:roleinfo.id,status:roleinfo.status});
            if(res.code !== 200){
                roleinfo.status = !roleinfo.status
                return this.$message.error('更新角色状态失败');
            }
            return this.$message.success('更新角色状态成功');
        },


        //批量上架
        async shelfStateChangedUp(){
            //console.log(statusinfo);
            //询问是否上架
            //this.$refs.authFormRef.validate(async valid=>{})
                
                const confirmResult = await this.$confirm('是否确认批量启用', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).catch(err => err)

                //如果确认删除，返回字符串confirm，如果点击取消，则返回值魏字符串cancel
                console.log(confirmResult)
                if(confirmResult !== 'confirm'){
                    return this.$message.info('已经取消');
                }
                console.log('确认了上架');
                
                let checkArr = this.multipleSelection;   // multipleSelection存储了勾选到的数据
                let idsList = [];
                checkArr.forEach(function (item) {     
                    idsList.push(item.id);       // 添加所有需要删除数据的id到一个数组，post提交过去
                });
                
                let ids = idsList.join(',');
                console.log(ids);
                //return this.$message.info('暂停一下！');


                const {data:res} = await this.$http.put('sys/role/frozenBatch',{ids:ids,status:1});
                if(res.code !== 200){
                    //statusinfo.shelfStatus = '0'
                    return this.$message.error('批量更新启用状态失败');
                }
                this.$message.success('批量更新启用状态成功');
                this.getRolesList();//重新获取列表数据
            
        },

        //批量下架
        async shelfStateChangedDown(){
            //console.log(statusinfo);
            //询问是否上架
            //this.$refs.authFormRef.validate(async valid=>{})
                
                const confirmResult = await this.$confirm('是否确认批量禁用', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).catch(err => err)

                //如果确认删除，返回字符串confirm，如果点击取消，则返回值魏字符串cancel
                console.log(confirmResult)
                if(confirmResult !== 'confirm'){
                    return this.$message.info('已经取消');
                }
                console.log('确认了下架');
                
                let checkArr = this.multipleSelection;   // multipleSelection存储了勾选到的数据
                let idsList = [];
                checkArr.forEach(function (item) {     
                    idsList.push(item.id);       // 添加所有需要删除数据的id到一个数组，post提交过去
                });
                
                let ids = idsList.join(',');
                console.log(ids);
                //return this.$message.info('暂停一下！');


                const {data:res} = await this.$http.put('sys/role/frozenBatch',{ids:ids,status:2});
                if(res.code !== 200){
                    //statusinfo.shelfStatus = '0'
                    return this.$message.error('批量更新禁用状态失败');
                }
                this.$message.success('批量更新禁用状态成功');
                this.getRolesList();//重新获取列表数据
            
        },
        
        //批量删除     
        //promis用于异步操作，asyne／await语法糖将异步操作的同步写法 根then用法是一样的 还避免了回调地狱
        //将同步写异步代码，代码会采用非阻塞的方式继续执行下去，解决回调地狱的问题
        async removeByIds(){
            //询问是否删除
            const confirmResult = await this.$confirm('是否确认删除选中的角色？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            //如果用户确认删除，返回字符串confirm，如果用户点击取消，则返回值魏字符串cancel
            console.log(confirmResult)
            if(confirmResult !== 'confirm'){
                return this.$message.info('已经取消删除');
            }
            console.log('确认了删除');

            let checkArr = this.multipleSelection;   // multipleSelection存储了勾选到的数据
            let idsList = [];
            checkArr.forEach(function (item) {     
                idsList.push(item.id);       // 添加所有需要删除数据的id到一个数组，post提交过去
            });
            
            let ids = idsList.join(',');
            console.log(ids);

            const {data:res} = await this.$http.delete('sys/role/deleteBatch?ids='+ids);
            if(res.code !== 200) {
                //少一个return？
                return this.$message.error('批量删除失败');
            } 
            this.$message.success('批量删除成功');
            console.log("批量删除成功");
            this.getRolesList();//重新获取列表数据
            console.log("刷新列表");
        },
    }
}
</script>

<style lang="less" scoped>

</style>